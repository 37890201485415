
import { Vue, Component, PropSync, Prop } from "vue-property-decorator";
import gql from "graphql-tag";
import CircleLoading from "@/components/loading/CircleLoading.vue";
import SkinsplusItem from "@/components/buy/SkinsplusItem.vue";
import { sumBy } from "lodash";

@Component({
  components: { SkinsplusItem, CircleLoading },
  apollo: {
    items: {
      query: gql`
        query($steamBotIds: [ID!]!) {
          getAllSteamBotItemsBySteamBotIds(steamBotIds: $steamBotIds)
        }
      `,
      update(data) {
        return data.getAllSteamBotItemsBySteamBotIds || [];
      },
      variables() {
        return {
          steamBotIds: this.steamBots.map(({ _id }) => _id)
        };
      },
      fetchPolicy: "no-cache"
    }
  }
})
export default class SteamBotItemsListDialog extends Vue {
  @PropSync("value", { type: Boolean }) show!: Boolean;
  @Prop() steamBots!: any[];
  @Prop() tradeLink!: string;
  private tradeUrl: string = "";
  private tradesCount: number = 10;
  private selectedItems: any[] = [];
  private items: any[] = [];
  private loading = false;
  private searchText = "";
  private minPrice: null | number = null;
  private maxPrice: null | number = null;

  get filteredAndSortedItems() {
    return (this.searchText
      ? this.items.filter(
          ({ name }) =>
            name.toLowerCase().indexOf(this.searchText.toLowerCase()) !== -1
        )
      : this.items
    ).sort((a, b) => {
      return b.price - a.price;
    });
  }

  get btnDisabled() {
    return (
      !/(https?:\/\/steamcommunity\.com\/tradeoffer\/new\/\?partner=\d+&token=[a-zA-Z0-9_-]+)/i.test(
        this.tradeUrl
      ) || !this.items.length
    );
  }

  created() {
    this.tradeUrl = this.tradeLink;
  }

  select(item: any) {
    const index = this.selectedItems.findIndex(({ id }) => item.id === id);

    if (index !== -1) {
      this.selectedItems.splice(index, 1);
    } else {
      this.selectedItems.push(item);
    }
  }

  selectAll() {
    this.selectedItems = (this.filteredAndSortedItems || this.items)
      .filter(v => {
        if (typeof this.minPrice === "number" && v.price < this.minPrice) {
          return false;
        }

        if (typeof this.maxPrice === "number" && v.price > this.maxPrice) {
          return false;
        }

        return true;
      })
      .slice();
  }

  async send() {
    const maxItemsPrice = 50000;
    const maxItemsCount = 900;
    let currentItemsPrice = 0;
    let currentItemsCount = 0;

    let currentTradeUrl = this.tradeUrl.split(",");
    this.tradeUrl = currentTradeUrl[0];

    if (currentTradeUrl.length > 1 && !this.selectedItems.length) {
      this.$q.notify({
        message: "Для вывода на несколько tradeUrl нужно выбрать предметы",
        color: "negative",
        position: "bottom"
      });
      return;
    }

    const _send = async (steamBotId: string, items: any[]) => {
      if (!this.tradeUrl) return;
      if (!items.length) return;
      const _items = items.slice();

      let index: number | null = null;
      let isIndexFound = false;

      _items.forEach((item, i) => {
        if (!isIndexFound) {
          if (
            currentItemsPrice + item.price <= maxItemsPrice &&
            currentItemsCount < maxItemsCount
          ) {
            currentItemsPrice += item.price;
            currentItemsCount += 1;
          } else {
            index = i;
            isIndexFound = true;
          }
        }
      });

      console.log(steamBotId, index, "steamBotId index");
      if (index === null) {
        return await this.sendAllSteamBotItems(steamBotId, _items);
      }

      const currentItemsArr = _items.slice(0, index);
      if (currentItemsArr.length) {
        await this.sendAllSteamBotItems(steamBotId, currentItemsArr);
      }

      currentItemsCount = 0;
      currentItemsPrice = 0;
      currentTradeUrl = currentTradeUrl.slice(1);
      this.tradeUrl = currentTradeUrl[0];

      console.log(this.tradeUrl, "this.tradeUrl");

      if (_items.slice(index).length) {
        await _send(steamBotId, _items.slice(index));
      }
    };

    for await (let steamBot of this.steamBots) {
      if (!this.tradeUrl) return;
      if (!this.selectedItems.length) {
        await this.sendAllSteamBotItems(steamBot._id, []);
      }

      const items = this.selectedItems.filter(
        item => item.steamBotId === steamBot._id
      );

      if (items.length) {
        await _send(steamBot._id, items);
      }
    }
  }

  async sendAllSteamBotItems(steamBotId: string, items: any[], attempts = 3) {
    this.loading = true;
    try {
      await this.$apollo.mutate({
        variables: {
          steamBotId,
          tradeUrl: this.tradeUrl,
          ...(items.length
            ? { steamBotItemIds: items.map(({ id }) => id) }
            : {})
        },
        mutation: gql`
          mutation sendAllSteamBotItems(
            $steamBotId: ID!
            $tradeUrl: String!
            $steamBotItemIds: [ID!]
          ) {
            sendAllSteamBotItems(
              steamBotId: $steamBotId
              tradeUrl: $tradeUrl
              steamBotItemIds: $steamBotItemIds
            )
          }
        `
      });
      this.$q.notify({
        message: `Успех`,
        multiLine: true,
        type: "positive"
      });
      this.show = false;
    } catch (e) {
      if (--attempts) {
        return this.sendAllSteamBotItems(steamBotId, items, attempts);
      }

      this.$q.notify({
        message: e.message,
        multiLine: true,
        type: "negative"
      });
    }

    this.loading = false;
  }
}
